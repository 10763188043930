import { useState } from "react";
import { analytics } from "lib/segment";

const NewsletterSignupPanel = () => {
  const [email, setEmail] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState("");

  // We don't need to add a newsletter signup endpoint to the API because Mailmodo will add new Segment Contacts automatically.
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    analytics?.identify(email, {
      email: email,
    });
    analytics?.track("Submitted Form", {
      form: "Newsletter Signup",
      email: email,
      userId: email,
    });
    await fetch("/api/newsletter-signup", { method: "POST", body: JSON.stringify({ email }) }).then((res) => {
      if (!res.ok) {
        res.json().then((json) => {
          setError(json.message);
        });
        throw new Error("Failed to submit newsletter signup");
      } else {
        setSubmitted(true);
      }
    });
  };

  return (
    <>
      <form
        onSubmit={handleSubmit}
        className="dark relative overflow-hidden rounded-lg bg-[url(/assets/images/newletter-signup-bg.webp)] bg-cover bg-center p-sm"
      >
        <div className="relative z-10 flex flex-col items-center justify-between text-primary-neutral-50 sm:flex-row">
          <h2 className="font-display text-2xl font-bold leading-extra-tight sm:max-w-xs sm:pl-md sm:text-xl lg:max-w-md lg:pl-16 xl:pl-2xl">
            Subscribe to our monthly newsletter
          </h2>
          {!submitted ? (
            <div className="flex w-full flex-shrink-0 flex-col space-y-2xs sm:w-1/2 sm:flex-row sm:space-x-2xs sm:space-y-0">
              <label htmlFor="email" className="sr-only">
                Email
              </label>
              <input
                className="w-full rounded px-sm py-2xs text-xs text-primary-dark-800"
                placeholder="Enter your email"
                type="email"
                id="email"
                required
                value={email}
                onChange={(event) => setEmail(event.target.value)}
              />
              <button
                type="submit"
                className="rounded border border-primary-light-500 bg-primary-light-500 px-sm py-2xs font-display text-xs font-bold transition-colors hover:bg-primary-light-500/20"
              >
                Subscribe
              </button>
            </div>
          ) : !!error ? (
            <p className="font-display text-xs font-bold text-primary-neutral-50">
              There was an error. Please refresh and try again.
            </p>
          ) : (
            <p className="font-display text-xs font-bold text-primary-neutral-50">Thank you for subscribing!</p>
          )}
        </div>
      </form>
    </>
  );
};

export default NewsletterSignupPanel;
