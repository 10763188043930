import CodeSnippets from "components/CodeSnippets/CodeSnippets";
import Hyperlink from "components/Hyperlink";
import VideoComponent from "components/VideoComponent/VideoComponent";
import ReactMarkdown from "react-markdown";
import rehypeSlug from "rehype-slug";
import remarkGfm from "remark-gfm";
import remarkUnwrapImages from "remark-unwrap-images";

const RenderMarkdown = ({ markdown }: { markdown: string | undefined }) => {
  return (
    <ReactMarkdown
      remarkPlugins={[remarkGfm, remarkUnwrapImages]}
      rehypePlugins={[rehypeSlug]}
      components={{
        h1: ({ children, id }) => (
          <h1 id={id} className="mb-sm mt-md font-display text-3xl font-black leading-snug">
            {children}
          </h1>
        ),
        h2: ({ children, id }) => (
          <h2 id={id} className="mb-sm mt-md font-display text-2xl font-black leading-snug">
            {children}
          </h2>
        ),
        h3: ({ children, id }) => (
          <h3 id={id} className="mb-sm mt-md font-display text-xl font-black">
            {children}
          </h3>
        ),
        h4: ({ children, id }) => (
          <h4 id={id} className="text-lg font-bold">
            {children}
          </h4>
        ),
        p: ({ children }) => <p className="my-xs">{children}</p>,
        strong: ({ children }) => <span className="font-medium">{children}</span>,
        a: ({ children, href }) => (
          <Hyperlink className="underline decoration-primary-dark-500 underline-offset-2" href={href ?? "#"}>
            {children}
          </Hyperlink>
        ),
        em: ({ children }) => <em>{children}</em>,
        u: ({ children }) => <span className="underline">{children}</span>,
        li: ({ children }) => <li className="my-xs">{children}</li>,
        ul: ({ children }) => <ul className="space-y-2xs pl-sm [&_li]:list-disc">{children}</ul>,
        ol: ({ children }) => <ol className="space-y-2xs pl-sm [&_li]:list-decimal">{children}</ol>,
        pre: ({ children }) => <>{children}</>,
        table: ({ children }) => (
          <div className="my-xs text-xs md:text-sm">
            <table className="overflow-hidden rounded-lg bg-primary-dark-50 [&_td]:border-r [&_td]:border-r-primary-dark-900/20 [&_td]:p-2xs last:[&_td]:border-r-0 [&_th]:bg-primary-dark-900 [&_th]:p-2xs [&_th]:text-primary-neutral-50 [&_tr]:border-b [&_tr]:border-b-primary-dark-900/20 last:[&_tr]:border-b-0">
              {children}
            </table>
          </div>
        ),
        blockquote: ({ children }) => (
          <blockquote className="my-xs border-l-4 border-primary-dark-900/20 pl-sm text-lg italic">
            {children}
          </blockquote>
        ),
        td: ({ children }) => {
          if (Array.isArray(children)) {
            children = children?.map((node) => (typeof node !== "string" ? node : node.replaceAll("\\n", "\n")));
          } else {
            children = typeof children !== "string" ? children : children.replaceAll("\\n", "\n");
          }
          return <td className="whitespace-pre-line">{children}</td>;
        },
        code: ({ className, children }) => {
          if (className?.length && className.length > 0) {
            return (
              <CodeSnippets
                node={{
                  code: children,
                  language: className?.split("-")[1],
                }}
              />
            );
          } else {
            return (
              <code className="inline-block break-all rounded bg-primary-dark-100 px-2xs font-mono text-[90%] leading-snug">
                {children}
              </code>
            );
          }
        },
        img: ({ src, alt }) => {
          if (src?.includes("youtube.com" || "youtu.be")) {
            return <VideoComponent url={src} title={alt ?? ""} />;
          }
          if (src?.includes("demo.arcade.software")) {
            return (
              <iframe
                src={src}
                title={alt ?? ""}
                className="aspect-[1.6] h-auto w-full rounded-lg"
                frameBorder="0"
                allowFullScreen
              ></iframe>
            );
          }
          return (
            // eslint-disable-next-line @next/next/no-img-element
            <img
              src={"https:" + src}
              alt={alt ?? "Markdown image"}
              loading="lazy"
              className="my-xs w-full rounded-lg"
            />
          );
        },
        video: ({ src, title }) => (
          <video
            src={src}
            title={title}
            className="mx-auto my-sm h-auto w-full max-w-screen-lg overflow-hidden rounded-lg"
            width="100%"
            height="auto"
            muted
            autoPlay
            loop
            playsInline
          >
            <source src={src} />
          </video>
        ),
      }}
    >
      {markdown ?? ""}
    </ReactMarkdown>
  );
};

export default RenderMarkdown;
